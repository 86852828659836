<script lang="ts">
	import { getOrderType } from '$lib/service/OrderService';
	import type { MarketOrderDetails } from '$lib/types';
	import CardPriceTag from './CardPriceTag.svelte';
	import TankCard from './TankCard.svelte';
	export let item: MarketOrderDetails;
	export let status = '';

	$: orderType = getOrderType(item);
</script>

<TankCard
	bind:userLike={item.userLike}
	contract={item.metadata.contract}
	network={item.metadata.network}
	tokenID={item.metadata.tokenId}
	orderID={item.id}
	{orderType}
	{status}
>
	<svelte:fragment slot="price">
		<CardPriceTag {item} />
	</svelte:fragment>
</TankCard>

<style>
</style>
